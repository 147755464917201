@font-face {
  font-family: "HelveticaNeue for Target";
  src: url(../assets/fonts/HelveticaforTarget-Rm.otf) format('opentype');
}

body {
  color: #454545;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  font-family: "HelveticaNeue for Target, Helvetica Neue, Helvetica, Arial, sans-serif", HelveticaNeue for Target;
}
